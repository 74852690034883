<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>1688상품 수집 방법</u></p>
                            <b-modal id="modal-3" size="lg" title="상품 수집 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 상품을 수집해 주세요.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/extention_collect.png')">
                            </b-modal>
                    </div>
                    <!-- <router-link :to="link" class="btn btn-primary add-list"><i class="las la-plus mr-3"></i>+{{linktext}}</router-link> -->
                </div>
            <card>
               <div class="p-3">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio7">오늘</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio8">최근7일</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                </div>
                                <!-- <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                    <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                </div> -->
                            </div>
                            <div class="col-md-12 mt-2">
                                <b-input-group>
                                    <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                        <option>상품수집일</option>
                                        <!-- <option>입고예정일</option> -->
                                    </select>
                                    <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                    <p>~</p>
                                    <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>
                                    
                                </b-input-group>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="상품명" v-model="상품명">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="상품번호" v-model="상품번호">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="등록링크" v-model="등록링크">
                                </div>
                            </div>                             
                            <div class="col-lg-12 mt-3">
                                <button type="button" class="btn btn-primary mr-2" @click="search">검색</button>
                                <!-- <button type="button" class="btn btn-success" v-b-modal.modalsetapi>번역API설정</button> -->
                                
                                <!-- <div class="float-right mr-2">
                                    <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkapply">
                                        <label class="custom-control-label" for="customSwitch2">동일번역 일괄적용</label>
                                    </div>
                                </div>
                                
                                            <b-modal id="modalsetapi" size="lg" title="번역 API 설정" ok-title="저장" cancel-title="취소" @ok="savepapagoapi">
                                                <card>
                                                    <div class="p-3 row">
                                                        <div class="col-12">
                                                            <u v-b-modal.modalapi>파파고 번역 API Key 발급 방법</u>
                                                        </div>
                                                            <b-modal id="modalapi" size="xl" title="파파고 API Key 발급 방법" ok-only>
                                                                <p><a href="https://developers.naver.com/apps/#/register?api=ppg_n2mt" target="_blank"><u>네이버개발자센터</u></a>에 접속해서 아래와 같이 "애플리케이션"을 등록 한후 Client ID와 Client Secret key를 입력해 주세요.</p>
                                                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/papagoapi.png')">
                                                            </b-modal>
                                                        <div class="col-4">
                                                            <label class="ml-1">Client ID</label>
                                                            <input type="text" class="form-control" placeholder="Client ID" v-model="papagoid">
                                                        </div>
                                                        <div class="col-4">
                                                            <label class="ml-1">Client Secret</label>
                                                            <input type="text" class="form-control" placeholder="Client Secret" v-model="papagosecret">
                                                        </div>
                                                        <div class="col-12 mt-3">
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadio37" name="customRadio-12" class="custom-control-input" v-model="translatepriority" value="추천">
                                                                <label class="custom-control-label" for="customRadio37" v-b-tooltip.bottom="'데이터베이스 상 가장 많이 번역된 옵션명을 우선적으로 적용합니다.'">추천번역우선</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadio38" name="customRadio-12" class="custom-control-input" v-model="translatepriority" value="파파고">
                                                                <label class="custom-control-label" for="customRadio38" v-b-tooltip.bottom="'파파고API를 통한 번역을 우선적으로 적용합니다.'">파파고번역우선</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </card> 
                                            </b-modal> -->



                                <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                            </div>
                        </div>

                    </form>  
               </div>
            </card> 
            </div>
            <div class="col-lg-12" v-if="showlist">
                <div class="p-3">
                    <div class="row">
                        <button type="button" class="btn btn-primary mr-3" @click="savereglists">저장</button>
                        <button type="button" class="btn btn-secondary mr-3" @click="deletecollectlists">선택삭제</button>
                        <!-- <button type="button" class="btn btn-success mr-3" v-b-tooltip.bottom="'마우스 우클릭 > 한국어로 번역' + '\n' + '또는 크롬브라우저 상단 페이지번역을 먼저한 후에 페이지 번역 적용 버튼을 눌러주시면 상품명과 옵션명이 번역적용 됩니다.'" @click="applytranslate">페이지 번역 적용</button> -->
                    </div>
                    <div class="row mt-4">
                        <div class="table-responsive rounded mb-3">
                            <tableList>
                                <template v-slot:header>
                                    <tr class="ligth ligth-data text-center">
                                        <th>
                                            <div class="checkbox d-inline-block">
                                                <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="checkalllists" @change="checkalllist">
                                                <label for="checkbox2" class="mb-0"></label>
                                            </div>
                                        </th>
                                        <th style="max-width:50px;font-size:90%">등록번호</th> 
                                        <th v-if="!priceon" style="max-width:150px;font-size:90%">중국어상품명</th>
                                        <th style="max-width:150px;font-size:90%">한국어상품명</th>
                                        <!-- <th>상품특징(상품명앞머리)</th>
                                        <th>키워드</th> -->
                                        <th style="font-size:90%">등록링크</th>
                                        <th style="font-size:90%">이미지</th>
                                        <th style="font-size:90%">상세페이지</th>
                                        <th style="font-size:90%">Actions</th>
                                    </tr>
                                </template>

                                <template v-slot:body >        
                                    <tr v-for="(reg,index) in reglists" :key="index">
                                        <td @click="reg.checked = !reg.checked" class="text-center">
                                            <div class="checkbox d-inline-block">
                                                <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="reg.checked">
                                                <label for="checkbox2" class="mb-0"></label>
                                            </div>
                                        </td>
                                        <td style="font-size:80%" class="text-center">
                                            <div v-if="!reg.showrequest">
                                                {{reg.등록번호}}
                                            </div>
                                            <div v-else>
                                                <button type="button" class="btn btn-secondary mr-3" @click="reqcollect(reg)">수집요청</button>
                                            </div>
                                        </td>
                                        <td style="font-size:80%" class="text-center" v-b-modal="modalID(index)">{{reg.상품명}}</td>
                                        <td style="font-size:80%" class="text-center" v-b-modal="modalID(index)">{{reg.상품명kr}}</td>
                                        <!-- <td>{{reg.상품특징}}</td>
                                        <td>
                                            {{reg.키워드}}
                                        </td> -->
                                        <td style="font-size:80%" class="text-center">
                                            <div v-b-modal="modalID(index)">
                                                {{reg.상품번호}}
                                            </div>
                                            <a :href="reg.등록링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                        <td style="font-size:80%" class="text-center">
                                            <div class="justify-content-center" v-b-modal="modalID(index)" v-if="reg.썸네일">
                                                <img :src="reg.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                            </div>
                                        </td>
                                        <td style="font-size:120%" class="text-center">
                                            <div :class="detailpageclass(reg.상세페이지)" @click="makepageconfirm(index,reg.상세페이지)">
                                                {{reg.상세페이지 ? "Created" : "Not created"}}
                                            </div>
                                        </td>
                                        <td style="font-size:80%" class="text-center">
                                            <div class="d-flex align-items-center list-action">
                                                <!-- <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="최적화 키워드 추출(기능 준비중)"
                                                    href="javascript:void(0)" v-b-modal="modalID(index)"><i class="ri-eye-line mr-0"></i></a> -->
                                                    <b-modal :id="'modal'+index" size="xl" title="상품상세내역" cancel-title="취소" ok-title="저장" @cancel="canceledit(index)">
                                                        <div class="col-lg-12">
                                                            <card class="p-3">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <button type="button" class="btn btn-success mr-3 d-flex float-right ml-3" v-b-tooltip.bottom="'마우스 우클릭 > 한국어로 번역' + '\n' + '또는 크롬브라우저 상단 페이지번역을 먼저한 후에 페이지 번역 적용 버튼을 눌러주시면 상품명과 옵션명이 번역적용 됩니다.'" @click="applytranslateoption(index)">페이지 번역 적용</button>
                                                                        <button type="button" class="btn btn-primary mr-3" @click="priceon = !priceon">가격설정</button>
                                                                        <button type="button" class="btn btn-success mr-3" @click="downloadexcel(reg)">다운로드</button>
                                                                        <button type="button" class="btn btn-secondary mr-3" @click="makepageconfirm(index,false)">상세페이지 생성</button>
                                                                        <button type="button" class="btn btn-danger mr-3" @click="deleteAll(reg)">일괄삭제</button>
                                                                        <div class="custom-control custom-switch custom-control-inline d-flex float-right mr-3">
                                                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkapply">
                                                                            <label class="custom-control-label" for="customSwitch2">동일번역 일괄적용</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-4" v-if="priceon">
                                                                    <div class="col-md-1">
                                                                        <div class="form-group">
                                                                            <input type="number" class="form-control" placeholder="판매가비율" v-model="판매가비율">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label class="ml-1"><b>쿠팡마진 비율</b>&nbsp;&nbsp;&nbsp;<small>(ex) 35 &nbsp; ---> &nbsp; 공급가(KRW) / (100% - 35%) = 판매가(KRW)</small></label>
                                                                    </div>
                                                                </div>
                                                                <div class="row" v-if="priceon">
                                                                    <div class="col-md-1">
                                                                        <div class="form-group">
                                                                            <input type="number" class="form-control" placeholder="공급가배율" v-model="공급가배율">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label class="ml-1"><b>공급가 배율</b>&nbsp;&nbsp;&nbsp;<small>(ex) 500 &nbsp; ---> &nbsp; 공급가(KRW) = 구매가(CNY) x 500</small></label>
                                                                    </div>
                                                                </div>
                                                                <div class="row" v-if="priceon">
                                                                    <div class="col-md-1">
                                                                        <div class="form-group">
                                                                            <input type="number" class="form-control" placeholder="판매가반올림단위" v-model="판매가반올림단위">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-11">
                                                                        <label class="ml-1"><b>쿠팡 판매가 반올림 자릿수</b>&nbsp;&nbsp;&nbsp;<small>(ex) 2 &nbsp; ---> &nbsp; 판매가(12356) -> 판매가(12400)</small></label>
                                                                    </div>
                                                                </div>
                                                            </card>

                                                            
                                                            <div class="table-responsive rounded mb-3">
                                                                <table id="datatable2" class="table data-table table-striped dataTable text-center" >
                                                                    <thead>
                                                                        <tr class="ligth ligth-data text-center">
                                                                            <th>
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allcheck" @change="checkall(reg)">
                                                                                    <label for="checkbox1" class="mb-0"></label>
                                                                                </div>
                                                                            </th>
                                                                            <th style="max-width:50px;font-size:90%">등록번호<br>등록옵션번호</th> 
                                                                            <th v-if="!priceon" style="max-width:150px;font-size:90%">중국어상품명</th>
                                                                            <th style="max-width:150px;font-size:90%">한국어상품명</th>
                                                                            <!-- <th>상품특징(상품명앞머리)</th>
                                                                            <th>키워드</th> -->
                                                                            <th style="font-size:90%">등록링크</th>
                                                                            <th style="font-size:90%">옵션이미지</th>
                                                                            <th v-if="!priceon" style="font-size:90%">중국어옵션1</th>
                                                                            <th v-if="!priceon" style="font-size:90%">중국어옵션2</th>
                                                                            <th style="font-size:90%">한국어옵션1</th>
                                                                            <th style="font-size:90%">한국어옵션2</th>

                                                                            <th v-if="priceon" style="font-size:90%">구매가격(CNY)</th>
                                                                            <th v-if="priceon" style="font-size:90%">쿠팡공급가(원)</th>
                                                                            <th v-if="priceon" style="font-size:90%">쿠팡판매가(원)</th>


                                                                            <th v-if="!priceon" style="font-size:90%">재고</th>
                                                                            <th v-if="!priceon" style="max-width:100px;font-size:90%">옵션조합명(한국어)</th>
                                                                            <th style="font-size:90%">Actions</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>      
                                                                        <tr v-for="(r,index) in reg.상세내역" :key="index">
                                                                            <td @click="r.checked = !r.checked">
                                                                                <div class="checkbox d-inline-block">
                                                                                    <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="r.checked">
                                                                                    <label for="checkbox2" class="mb-0"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td style="font-size:80%">
                                                                                <div v-if="!r.showrequest">
                                                                                    {{r.등록번호}}<br>{{r.등록옵션번호}}
                                                                                </div>
                                                                                <div v-else>
                                                                                    <button type="button" class="btn btn-secondary mr-3" @click="reqcollect(r)">수집요청</button>
                                                                                </div>
                                                                            </td>
                                                                            <td v-if="!priceon" style="font-size:80%">{{r.상품명}}</td>
                                                                            <td style="font-size:80%">
                                                                                <div v-if="r.shownameinput">
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" placeholder="상품명 입력" v-model="r.상품명kr" @change="changename(r)" @keyup.enter="r.shownameinput = false">
                                                                                    </div>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <div @click="r.shownameinput = !r.shownameinput">
                                                                                        {{r.상품명kr}}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <!-- <td>{{r.상품특징}}</td>
                                                                            <td>
                                                                                {{r.키워드}}
                                                                            </td> -->
                                                                            <td style="font-size:80%" class="text-center">{{r.상품번호}}<br><a :href="r.등록링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                                                            <td style="font-size:80%">
                                                                                <div class="d-flex align-items-center" v-if="r.옵션1_이미지">
                                                                                    <img :src="r.옵션1_이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                                </div>
                                                                            </td>
                                                                            <td v-if="!priceon" style="font-size:80%">{{r.옵션1_중국어}}</td>
                                                                            <td v-if="!priceon" style="font-size:80%">{{r.옵션2_중국어}}</td>
                                                                            <td style="font-size:80%">
                                                                                <div v-if="r.showop1input">
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" placeholder="옵션명 입력" v-model="r.옵션1_한국어" @change="changeop1(r,index)" @keyup.enter="r.showop1input = false">
                                                                                    </div>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <div @click="r.showop1input = !r.showop1input">
                                                                                        {{r.옵션1_한국어}}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td style="font-size:80%">
                                                                                <div v-if="r.showop2input">
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" placeholder="옵션명 입력" v-model="r.옵션2_한국어" @change="changeop2(r,index)" @keyup.enter="r.showop2input = false">
                                                                                    </div>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <div @click="r.showop2input = !r.showop2input">
                                                                                        {{r.옵션2_한국어}}
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td v-if="priceon" style="font-size:90%">{{r['구매가격(CNY)']}}</td>
                                                                            <td v-if="priceon" style="font-size:90%">{{r['구매가격(CNY)'] * 공급가배율}}</td>
                                                                            <td v-if="priceon" style="font-size:90%">{{Math.round((r['구매가격(CNY)'] * 공급가배율 / ((100-판매가비율)/100)) / (10 ** 판매가반올림단위)) * (10 ** 판매가반올림단위)}}</td>


                                                                            <td v-if="!priceon" style="font-size:80%">{{r.재고}}</td>
                                                                            <td v-if="!priceon" style="font-size:80%">
                                                                                <div v-if="r.옵션조합_한국어">{{r.옵션조합_한국어}}</div>
                                                                                <div v-else>{{r.옵션2_한국어 ? r.옵션1_한국어 + ', ' + r.옵션2_한국어 : r.옵션1_한국어}}</div>
                                                                            </td>
                                                                            <td style="font-size:80%">
                                                                                <div class="justify-content-center list-action">
                                                                                    <!-- <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="최적화 키워드 추출(기능 준비중)"
                                                                                        href="javascript:void(0)" v-b-modal="modalID(index)"><i class="ri-eye-line mr-0"></i></a> -->

                                                                                        <b-modal :id="'modaledit'+index" size="xl" title="상품명 키워드 최적화" cancel-title="Close">

                                                                                            <card>
                                                                                                <div class="p-3">
                                                                                                    <form>
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-12 mt-2">
                                                                                                                <p>상품명 : {{ProductList.상품명}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SKU ID : {{ProductList["SKU ID"]}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;바코드 : {{ProductList.바코드}}</p>
                                                                                                            </div>
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">등록이미지</label>
                                                                                                                <input type="text" class="form-control" placeholder="등록이미지" v-model="ProductList.등록이미지">
                                                                                                            </div>                                                            
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">구매링크</label>
                                                                                                                <input type="text" class="form-control" placeholder="구매링크" v-model="ProductList.구매링크">
                                                                                                            </div>
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">옵션1_중국어</label>
                                                                                                                <input type="text" class="form-control" placeholder="옵션1_중국어" v-model="ProductList.옵션1_중국어">
                                                                                                            </div>
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">옵션2_중국어</label>
                                                                                                                <input type="text" class="form-control" placeholder="옵션2_중국어" v-model="ProductList.옵션2_중국어">
                                                                                                            </div>
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">판매구성수량</label>
                                                                                                                <input type="text" class="form-control" placeholder="판매구성수량" v-model="ProductList.판매구성수량">
                                                                                                            </div>
                                                                                                            <div class="col-lg-4 mt-2">
                                                                                                                <label class="ml-1">구매가능상태</label>
                                                                                                                <input type="text" class="form-control" placeholder="구매가능상태" v-model="ProductList.구매가능상태">
                                                                                                            </div>                     
                                                                                                            <div class="col-lg-4 mt-4">                                                                                                   
                                                                                                            <button type="button" class="btn btn-primary mr-2 mb-4" @click="edit(ProductList)">수정 및 저장</button>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </form>  
                                                                                                </div>
                                                                                                
                                                                                            </card> 

                                                                                        </b-modal>


<!-- 
                                                                                    <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="상세페이지 생성"
                                                                                        href="javascript:void(0)" v-b-modal="'makepagemodal'+index"><i class="ri-pencil-line mr-0"></i></a> -->


                                                                                    <a @click="deletedetailitem(reg,index)" class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제"
                                                                                        href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </b-modal>





                                                    <b-modal :id="'modaledit'+index" size="xl" title="상품명 키워드 최적화" cancel-title="Close">

                                                        <card>
                                                            <div class="p-3">
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="col-lg-12 mt-2">
                                                                            <p>상품명 : {{ProductList.상품명}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SKU ID : {{ProductList["SKU ID"]}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;바코드 : {{ProductList.바코드}}</p>
                                                                        </div>
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">등록이미지</label>
                                                                            <input type="text" class="form-control" placeholder="등록이미지" v-model="ProductList.등록이미지">
                                                                        </div>                                                            
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">구매링크</label>
                                                                            <input type="text" class="form-control" placeholder="구매링크" v-model="ProductList.구매링크">
                                                                        </div>
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">옵션1_중국어</label>
                                                                            <input type="text" class="form-control" placeholder="옵션1_중국어" v-model="ProductList.옵션1_중국어">
                                                                        </div>
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">옵션2_중국어</label>
                                                                            <input type="text" class="form-control" placeholder="옵션2_중국어" v-model="ProductList.옵션2_중국어">
                                                                        </div>
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">판매구성수량</label>
                                                                            <input type="text" class="form-control" placeholder="판매구성수량" v-model="ProductList.판매구성수량">
                                                                        </div>
                                                                        <div class="col-lg-4 mt-2">
                                                                            <label class="ml-1">구매가능상태</label>
                                                                            <input type="text" class="form-control" placeholder="구매가능상태" v-model="ProductList.구매가능상태">
                                                                        </div>                     
                                                                        <div class="col-lg-4 mt-4">                                                                                                   
                                                                        <button type="button" class="btn btn-primary mr-2 mb-4" @click="edit(ProductList)">수정 및 저장</button>
                                                                        </div>
                                                                    </div>

                                                                </form>  
                                                            </div>
                                                            
                                                        </card> 

                                                    </b-modal>



                                                <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset"
                                                    href="javascript:void(0)" @click="resetreg(reg)" ><i class="ri-pencil-line mr-0"></i></a>

                                                    <b-modal :id="'makepagemodal' + index" size="xl" title="상세페이지 생성" cancel-title="닫기" ok-title="상세페이지 생성" @ok="makepage(reg)">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlTextarea1">상세페이지 상단 MD코멘트 입력 (생략가능)</label>
                                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="reg.mdcontent"></textarea>
                                                        </div>
                                                    </b-modal>

                                                <a @click="deleteitem(index)" class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제"
                                                    href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tableList>
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { core } from '../../../config/pluginInit'
// import { transcode } from 'buffer';
export default {
    name:'listproduct',
  
    data(){
        return{
            checkalllists: false,

            판매가반올림단위 : 2,
            판매가비율 : 35,
            공급가배율 : 500,
            priceon : false,
            checkapply : true,

            dotranslate : false,
            translatepriority : '추천',
            translated : {},

            번역: '',
            papagoid : '',
            papagosecret : '',

            allcheck : false,

            selectdate: 'today',
            selectoption: '상품수집일',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),

            상품명:'',
            상품번호:'',
            키워드:'',
            등록링크:'',

            reglists: [],
            reglistsinit: [],
            regrowlists: [],


            title:"상품등록(1688)",
            desc1:"1688 상품등록을 위해 상품정보와 상세페이지를 제작할 수 있는 페이지 입니다.",
            desc2:"",

            showlist: false,
            showspinner: false,
            
            ProductList : {
                등록이미지: '',
                구매링크: '',
                옵션1_중국어: '',
                옵션2_중국어: '',
                판매구성수량: '',
                구매가능상태: '',
            },


             link:{ name: 'product.addproduct'},
             linktext:"Add Product",
            selected: '',
        }
    },
    mounted(){
        // this.addscript();
    },
    methods: {
        canceledit(index){
            if(this.priceon){
                this.priceon = false;
            }
            this.reglists[index].상세내역 = [];
            this.reglists[index].상품명kr = this.reglistsinit[index].상품명kr;
            this.reglistsinit[index].상세내역.forEach(e => {
                this.reglists[index].상세내역.push(this.deepClone(e))
            })
        },
        makepageconfirm(index,foo){
            if(foo){
                if(confirm('상세페이지 생성을 취소하시겠습니까?')){
                    this.reglists[index].상세페이지 = false;
                }
            } else {
                this.$bvModal.show('makepagemodal' + index);
            }
        },
        resetreg(reg){
            reg.상품명kr = '';
            reg.상세내역.forEach(e => {
                e.상품명kr = '';
                e.옵션1_한국어 = '';
                e.옵션2_한국어 = '';
            })
        },
        checkalllist(){
            if(this.checkalllists){
                this.reglists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.reglists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        async deletecollectlists(){
            if(this.reglists.filter(e => e.checked).length > 0){
                if(this.reglists.filter(e => e.checked).length == this.reglists.length){
                    if(confirm('모든 상품을 삭제하시겠습니까?')){
                        await this.deletereglists(this.reglists)
                        this.reglists = [];
                    } else {
                        return
                    }
                }
                if(confirm(this.reglists.filter(e => e.checked).length + '개의 상품을 삭제하시겠습니까?')){
                    var arr = [];
                    var dellist = [];
                    this.reglists.forEach(e => {
                        if(!e.checked){
                            arr.push(e)
                        } else {
                            dellist.push(e)
                        }
                    })
                    await this.deletereglists(dellist)
                    this.reglists = arr;
                }
            } else {
                alert('삭제할 상품을 선택해 주세요')
            }
        },
        detailpageclass(foo){
            if(foo){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        savereglists(){
            if(this.reglists.filter(e => e.checked).length > 0){
                var arr = [];
                this.reglists.forEach(e => {
                    if(e.checked){
                        arr.push(e)
                    }
                })
                this.savereg(arr)
            } else {
                this.savereg(this.reglists)
            }
        },
        savereg(arr){
            axios.post('/api/regproduct/savereglist',arr)
            .then((res) => {
                console.log(res)
                this.$store.commit('settingsupdate',res.data.settings)
                alert(res.data.요청결과)
            })
            .catch(e => {
                console.log(e);
                alert('요청실패(282)')
            })
        },
        reqcollect(reg){
            axios.post('/api/regproduct/reqcollect',{
                companyid : this.$store.state.user.companyid,
                등록링크 : reg.등록링크,
            })
            .then((res) => {
                if(res.data.요청결과 == 'success'){
                    alert('관리자에 등록요청을 하였습니다. 등록완료까지 최대 24시간 소요될 수 있습니다.')
                }
            })
            .catch(e => {
                console.log(e);
                alert('요청실패(424)')
            })
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        async deletedetailitem(reg,index){
            if(confirm('삭제하시겠습니까?')){
                console.log(reg)
                console.log(index)
                var arr = [];
                reg.상세내역.forEach((e,i) => {
                    if(i !== index){
                        arr.push(e)
                    }
                })
                console.log(arr)
                reg.상세내역 = arr;
            }
        },
        async deleteAll(reg){
            if(reg.상세내역.filter(e => e.checked).length == 0){
                alert('삭제할 내역을 선택해 주세요')
            } else {
                if(confirm(reg.상세내역.filter(e => e.checked).length + '개의 내역을 삭제하시겠습니까?')){
                    var arr = [];
                    reg.상세내역.forEach(e =>{
                        if(!e.checked){
                            arr.push(e)
                        }
                    })
                    reg.상세내역 = arr
                }
            }
            
        },
        async deleteitem(index){
            if(confirm('삭제하시겠습니까?')){
                this.showlist = false;
                setTimeout(async () => {
                    var arr = [];
                    arr.push(this.reglists[index])
                    await this.deletereglists(arr)
                    var ar = [];
                    this.reglists.forEach((e,i) => {
                        if(i !== index){
                            ar.push(e)
                        }
                    })
                    this.reglists = ar;
                    this.showlist = true;
                },10)
            }
        },
        deletereglists(arr){
            axios.post('/api/regproduct/deletelist',arr)
            .then((res) => {
                if(res.data.요청결과 == 'success'){
                    alert('총 ' + res.data.요청수 + '개의 상품이 삭제되었습니다.')
                }
            })
            .catch(e => {
                console.log(e);
                alert('요청실패(705)')
            })
        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },  
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7
                    
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        makepage(reg){
            var regconfirm = false
            if(reg.상세내역.find(e => e.옵션1_중국어 && !e.옵션1_한국어)){
                alert('등록옵션번호:' + reg.상세내역.find(e => e.옵션1_중국어 && !e.옵션1_한국어).등록옵션번호 + '의 한국어옵션1이 기입되지 않았습니다.')
                regconfirm = true
            }
            if(reg.상세내역.find(e => e.옵션2_중국어 && !e.옵션2_한국어)){
                alert('등록옵션번호:' + reg.상세내역.find(e => e.옵션2_중국어 && !e.옵션2_한국어).등록옵션번호 + '의 한국어옵션2가 기입되지 않았습니다.')
                regconfirm = true
            }
            if(reg.상세내역.find(e => e.상품명 && !e.상품명kr)){
                alert('등록옵션번호:' + reg.상세내역.find(e => e.상품명 && !e.상품명kr).등록옵션번호 + '의 한국어상품명이 기입되지 않았습니다.')
                    regconfirm = true
            }
            if(regconfirm){
                return
            }
            reg.상세페이지 = true;
            this.makepagerequest(reg)
        },
        makepagerequest(reg){
            var obj = this.deepClone(reg);
            var option1array = [];
            
            reg.상세내역.forEach(e => {
                if(option1array.filter(ele => ele == e.옵션1_한국어).length == 0){
                    option1array.push(e.옵션1_한국어)
                }
            })
            obj.option1array = option1array;
            obj.minimagepixel = 400;
            obj.maxthumbqty = 5;
            window.postMessage({greeting: "makepage",reg:obj},"*",)
            console.log(reg)
        },
        downloadexcel(reg){
            var arr = [];
            var obj = {};
            console.log(reg)
            reg.상세내역.forEach(e => {
                obj = {
                    등록번호 : e.등록번호,
                    등록옵션번호 : e.등록옵션번호,
                    '상품명(등록용)' : e.옵션2_한국어 ? e.상품명kr + ', ' + e.옵션1_한국어 + ', ' + e.옵션2_한국어 : e.옵션1_한국어 ? e.상품명kr + ', ' + e.옵션1_한국어 : e.상품명kr,
                    '구매가격(CNY)' : e['구매가격(CNY)'],
                    '공급가(등록용)' : e['구매가격(CNY)'] * this.공급가배율,
                    '쿠팡판매가(등록용)' : Math.round((e['구매가격(CNY)'] * this.공급가배율 / ((100-this.판매가비율)/100)) / (10 ** this.판매가반올림단위)) * (10 ** this.판매가반올림단위),
                    '상품명(한국어)' : e.상품명kr,
                    '옵션1(한국어)' : e.옵션1_한국어,
                    '옵션2(한국어)' : e.옵션2_한국어,
                    '상품명(중국어)' : e.상품명,
                    '옵션1(중국어)' : e.옵션1_중국어,
                    '옵션2(중국어)' : e.옵션2_중국어,
                    '재고' : e.재고,
                    '등록링크' : e.등록링크,
                    '옵션이미지링크' : e.옵션1_이미지,
                }
                arr.push(obj)
            })
            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(arr)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '상품등록리스트')
            XLSX.writeFile(workBook, '상품등록리스트_'+ reg.등록번호 +'.xlsx')
        },
        async checkpage(){
            var res = await core.checkpage()
            return res
        },  
        // addscript(){
        //     let googletranslateScript = document.createElement('script')
        //     googletranslateScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
        //     document.head.appendChild(googletranslateScript)
        // },
        // googleTranslateElementInit(){
        //     new google.translate.TranslateElement({pageLanguage: 'ko',autoDisplay: true}, 'datatable');
        // },
        allpage(num){
            core.allpage(num)
        },
        changename(reg){
            if(this.checkapply){
                this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상품명kr = reg.상품명kr;
                    e.상세내역.forEach(ele => {
                        ele.상품명kr = reg.상품명kr;
                    })                    
                })
                this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상품명kr = reg.상품명kr;
                    e.상세내역.forEach(ele => {
                        ele.상품명kr = reg.상품명kr;
                    })
                })
            }
            reg.shownameinput = false;
            reg.changed = true;
        },
        changeop1(reg){
            if(this.checkapply){
                this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상세내역.filter(ele => ele.옵션1_중국어 == reg.옵션1_중국어).forEach(ele => {
                        ele.옵션1_한국어 = reg.옵션1_한국어;
                    })
                })
                this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상세내역.filter(ele => ele.옵션1_중국어 == reg.옵션1_중국어).forEach(ele => {
                        ele.옵션1_한국어 = reg.옵션1_한국어;
                    })
                })                
            }
            reg.showop1input = false;
            reg.changed = true;
        },
        changeop2(reg){
            if(this.checkapply){
                this.reglistsinit.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상세내역.filter(ele => ele.옵션2_중국어 == reg.옵션2_중국어).forEach(ele => {
                        ele.옵션2_한국어 = reg.옵션2_한국어;
                    })
                })
                this.reglists.filter(e => e.등록번호 == reg.등록번호 && e.상품명 == reg.상품명).forEach(e => {
                    e.상세내역.filter(ele => ele.옵션2_중국어 == reg.옵션2_중국어).forEach(ele => {
                        ele.옵션2_한국어 = reg.옵션2_한국어;
                    })
                })               
            }
            reg.showop2input = false;
            reg.changed = true;
        },
        productnameinit(reg){
            reg.상품명kr = '';
            reg.상품명 = '';
        },
        getpapagoapi(){
            console.log(this.$store.state.settings[0])
            if(this.$store.state.settings[0].papagoid && this.$store.state.settings[0].papagosecret){
                this.papagoid = this.$store.state.settings[0].papagoid;
                this.papagosecret = this.$store.state.settings[0].papagosecret;
            } else {
                alert('옵션명 번역을 위해 파파고 번역 API Key를 먼저 발급받아주세요.');
                this.$bvModal.show('modalsetapi');
            }
        },
        savepapagoapi(){
            if(!this.papagoid || !this.papagosecret){
                alert('API Key 발급 방법에 따라 발급 후 Client ID와 ClientSecret을 입력한 후 저장해주세요')
                return 
            }
            axios.post('/api/setpapagoapi',{
                id : this.papagoid,
                secret : this.papagosecret
            })
            .then((res) => {
                console.log(res)
                this.$store.commit('settingsupdate',res.data.settings)
                alert(res.data.요청결과)
            })
            .catch(e => {
                console.log(e);
                alert('요청실패(282)')
            })
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        }, 
        checkall(reg){
            if(this.allcheck){
                reg.상세내역.forEach(e => e.checked = true)
            } else {
                reg.상세내역.forEach(e => e.checked = false)
            }
        },
        edit(ProductList){
            this.$store.state.productdb.some(e => {
                if(e["SKU ID"] == ProductList["SKU ID"]){
                    e.등록이미지 = ProductList.등록이미지;
                    e.구매링크 = ProductList.구매링크;
                    e.옵션1_중국어 = ProductList.옵션1_중국어;
                    e.옵션2_중국어 = ProductList.옵션2_중국어;
                    e.판매구성수량 = ProductList.판매구성수량;
                    e.구매가능상태 = ProductList.구매가능상태;
                    axios.post('/api/addproduct/editproductdb',ProductList)
                    .then((res) => {console.log(res.data.요청결과)})
                    .catch(console.log)
                    return (e["SKU ID"] == ProductList["SKU ID"])
                }
            })
        },
        MenuUpdate() {
            console.log(this.selected)
            if (this.selected == "radio1") {
                this.menuint = this.menuDefault;
            } else {
                if (this.selected == "radio2") {
                    this.menuint = this.menuCost;
                }
                //  else {
                //     this.menuint = this.menuSales;
                // }
            }
        },
        modalID(index) {
            return 'modal' + index
        },
        modaledit(index) {
            return 'modaledit' + index
        },        
        searchpd(event) {
            if(event.keyCode === 13){
                this.search();
            }
        },
        async fetchreglists(){
            var res = await axios.post('/api/regproduct/getlist',{companyid:this.$store.state.user.companyid,start:this.startdate,end:this.enddate}).catch(e => {console.log(e);return false})
            if(res){
                return res.data.result
            }
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},   
        // async applytranslate(){
        //     var pageinfo = await this.checkpage()
        //     // this.googleTranslateElementInit();
        //     for(var i=pageinfo.start;i<pageinfo.end;i++){
        //         var j = i-pageinfo.start;
        //         if(!this.reglists[i].상품명kr){
        //             var 상품명kr = document.getElementById('datatable2').children[1].children[j].children[2].innerText;
        //             this.reglists[i].상품명kr = 상품명kr;
        //             this.reglists[i].상세내역.forEach(e => {
        //                 e.상품명kr = 상품명kr;
        //             })                 
        //         }
        //         this.reglists[i].shownameinput = false;
        //         this.reglists[i].changed = true;
        //     }
        // },
        async applytranslateoption(index){
            // var pageinfo = await this.checkpage()
            // this.googleTranslateElementInit();
            var i = index
            for(var j=0;j<document.getElementById('datatable2').children[1].children.length;j++){
                var 상품명kr = document.getElementById('datatable2').children[1].children[j].children[2].innerText;
                var 옵션1_한국어 = document.getElementById('datatable2').children[1].children[j].children[6].innerText;
                var 옵션2_한국어 = document.getElementById('datatable2').children[1].children[j].children[7].innerText;
                this.reglists[i].상품명kr = 상품명kr;
                this.reglists[i].상세내역[j].상품명kr = 상품명kr;
                this.reglists[i].상세내역[j].옵션1_한국어 = 옵션1_한국어;
                this.reglists[i].상세내역[j].옵션2_한국어 = 옵션2_한국어;
                this.reglists[i].shownameinput = false;
                this.reglists[i].changed = true;
            }
        },
        async translate(text){
            if(this.dotranslate){
                if(this.translated[text]){
                    return this.translated[text]
                }
                var res = await axios.post('/api/translate',{
                    id : this.papagoid,
                    secret : this.papagosecret,
                    text : text
                })
                .catch(e => {
                    console.log(e);
                    if(e.response.status == 429){
                        alert('파파고API의 하루요청 최대 횟수를 초과하였습니다.' + '\n' + '(5000자/회, 하루 10000자까지)')
                        this.dotranslate = false;
                        return {status : 429}
                    } else {
                        alert('번역실패')
                        return {status : e.response.status}
                    }
                })
                if(res.status == 200){
                    console.log(res)
                    this.translated[text] = res.data.message.result.translatedText;
                    return res.data.message.result.translatedText
                } else {
                    return ''   
                }
            } else {
                return ''
            }
        },
        padLeft(nr, n, str){
            return Array(n-String(nr).length+1).join(str||'0')+nr;
        },
        async search() {
            this.showspinner = true;
            this.reglists = [];
            this.reglistsinit = [];
            this.regrowlists = [];
            this.showlist = false;
            var res = await this.fetchreglists()
            if(res.length == 0){
                alert('수집된 내역이 없습니다.')
                this.showspinner = false;
                return
            }
            // console.log(res)
            var obj = {};
            var 상품명 = '';
            var 옵션1_한국어 = '';
            var 옵션2_한국어 = '';
            var 옵션조합_한국어 = '';
            var 구매가격 = 0;
            var cnt = 0;
            for(const e of res){
                if(e.save){
                    e.save.상세내역.forEach(ele => {
                        this.regrowlists.push(this.deepClone(ele))
                    })
                } else {
                    상품명 = '';
                    옵션1_한국어 = '';
                    옵션2_한국어 = '';
                    옵션조합_한국어 = '';
                    구매가격 = 0;
                    console.log(e.options)
                    if(e.상품명){
                        상품명 = e.상품명;
                    } else {
                        if(e.options && e.options.totalinfo && e.options.totalinfo.globalData){
                            상품명 = e.options.totalinfo.globalData.tempModel.offerTitle
                        }
                    }
                    if(!상품명){
                        if(e.options && e.options.totalinfo && e.options.totalinfo.globalData){
                            상품명 = e.options.totalinfo.globalData.tempModel.offerTitle
                        } else {
                            상품명 = ''
                        }
                    }
                    if(e.options && e.options.option1array.length > 0){
                        if(e.options.option2array.length > 0){
                            for(const [i,el1] of e.options.option1array.entries()){
                                for(const [j,el2] of e.options.option2array.entries()){
                                    if(e.options.option1arraykr && e.options.option1arraykr[i]){
                                        if(e.options.option2arraykr && e.options.option2arraykr[j]){
                                            옵션1_한국어 = e.options.option1arraykr[i];
                                            옵션2_한국어 = e.options.option2arraykr[j];
                                            옵션조합_한국어 = 옵션1_한국어 + ', ' + 옵션2_한국어;
                                        } else {
                                            옵션1_한국어 = e.options.option1arraykr[i];
                                            옵션2_한국어 = '';
                                            옵션조합_한국어 = 옵션1_한국어 + ', ' + 옵션2_한국어;
                                        }
                                    } else {
                                        옵션1_한국어 = '';
                                        옵션2_한국어 = '';
                                        옵션조합_한국어 = '';
                                    }
                                    if(e.options.totalinfo.globalData){
                                        if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuPriceType == 'skuPrice'){
                                            if(e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name + '&gt;' + el2.name]){
                                                구매가격 = e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name + '&gt;' + el2.name].discountPrice;
                                            } else {
                                                구매가격 = 0
                                            }
                                        } else {
                                            구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                                        }
                                    } else {
                                        구매가격 = 0
                                    }
                                    
                                    

                                    cnt++
                                    obj = {
                                        _id : e._id,
                                        checked : false,
                                        shownameinput : false,
                                        showop1input : false,
                                        showop2input : false,
                                        changed : false,
                                        showrequest : false,
                                        mdcomment : '',
                                        '구매가격(CNY)' : parseInt(구매가격),
                                        썸네일 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                                        상세페이지 : false,
                                        등록번호 : e.regnum,
                                        등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                                        상품명 : 상품명 ? 상품명 : '',
                                        상품명kr : e.productname ? e.productname : '',
                                        상품특징 : e.상품특징 ? e.상품특징 : '',
                                        키워드 : e.키워드 ? e.키워드 : '',
                                        상품번호 : e.productnum ? e.productnum : '',
                                        등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                        옵션1_이미지 : el1.imageUrl ? el1.imageUrl : '',
                                        옵션1_중국어 : el1.name ? el1.name : '',
                                        옵션2_중국어 : el2.name ? el2.name : '',
                                        옵션1_한국어 : 옵션1_한국어,
                                        옵션2_한국어 : 옵션2_한국어,
                                        // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                        재고 : e.options.optionstock[e.options.optioncomb.findIndex(f => f == el1.name + '&' + el2.name)],
                                        옵션조합_한국어 : 옵션조합_한국어
                                    }
                                    this.regrowlists.push(this.deepClone(obj))
                                }  
                            }
                        } else {
                            // console.log(e.options)
                            for(const [i,el1] of e.options.option1array.entries()){
                                if(e.options.option1arraykr && e.options.option1arraykr[i]){
                                    옵션1_한국어 = e.options.option1arraykr[i];
                                } else {
                                    옵션1_한국어 ='';
                                }   
                                if(e.options.totalinfo.globalData){
                                    if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuPriceType == 'skuPrice'){
                                        구매가격 = e.options.totalinfo.globalData.skuModel.skuInfoMap[el1.name].discountPrice;
                                    } else {
                                        if(e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices){
                                            구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                                        } else {
                                            구매가격 = 0;
                                        }
                                    } 
                                } else {
                                    구매가격 = 0;
                                }
                                
                                cnt++
                                obj = {
                                    _id : e._id,
                                    checked : false,
                                    shownameinput : false,
                                    showop1input : false,
                                    showop2input : false,
                                    changed : false,
                                    showrequest : false,
                                    mdcomment : '',
                                    '구매가격(CNY)' : parseInt(구매가격),
                                    썸네일 : e.options.totalinfo.globalData ? e.options.totalinfo.globalData.images[0].fullPathImageURI : '',
                                    상세페이지 : false,
                                    등록번호 : e.regnum,
                                    등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                                    상품명 : 상품명 ? 상품명 : '',
                                    상품명kr : e.productname ? e.productname : '',
                                    상품특징 : e.상품특징 ? e.상품특징 : '',
                                    키워드 : e.키워드 ? e.키워드 : '',
                                    상품번호 : e.productnum ? e.productnum : '',
                                    등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                    옵션1_이미지 : el1.imageUrl ? el1.imageUrl : '',
                                    옵션1_중국어 : el1.name ? el1.name : '',
                                    옵션2_중국어 : '',
                                    옵션1_한국어 : 옵션1_한국어,
                                    옵션2_한국어 : '',
                                    // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                    재고 : e.options.optionstock[e.options.optioncomb.findIndex(f => f == el1.name)],
                                    옵션조합_한국어 : 옵션1_한국어,
                                }
                                this.regrowlists.push(this.deepClone(obj))
                            }
                        }
                    } else {
                        if(e.options){
                            구매가격 = e.options.totalinfo.globalData.orderParamModel.orderParam.skuParam.skuRangePrices[0].price;
                            cnt++
                            obj = {
                                _id : e._id,
                                checked : false,
                                shownameinput : false,
                                showop1input : false,
                                showop2input : false,
                                changed : false,
                                showrequest : false,
                                mdcomment : '',
                                '구매가격(CNY)' : parseInt(구매가격),
                                썸네일 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                                상세페이지 : false,
                                등록번호 : e.regnum,
                                등록옵션번호 : e.regnum + this.padLeft(cnt,3),
                                상품명 : 상품명 ? 상품명 : '',
                                상품명kr : e.productname ? e.productname : '',
                                상품특징 : e.상품특징 ? e.상품특징 : '',
                                키워드 : e.키워드 ? e.키워드 : '',
                                상품번호 : e.productnum ? e.productnum : '',
                                등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                옵션1_이미지 : e.options.totalinfo.globalData.images[0].fullPathImageURI,
                                옵션1_중국어 : '',
                                옵션2_중국어 : '',
                                옵션1_한국어 : '',
                                옵션2_한국어 : '',
                                // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                재고 : e.options.totalinfo.globalData.orderParamModel.orderParam.canBookedAmount,
                                옵션조합_한국어 : '',
                            }
                            this.regrowlists.push(this.deepClone(obj))
                        } else {
                            구매가격 = 0;
                            cnt++
                            obj = {
                                _id : e._id,
                                checked : false,
                                shownameinput : false,
                                showop1input : false,
                                showop2input : false,
                                changed : false,
                                showrequest : true,
                                mdcomment : '',
                                '구매가격(CNY)' : '',
                                썸네일 : '',
                                상세페이지 : false,
                                등록번호 : '',
                                등록옵션번호 : '',
                                상품명 : '',
                                상품명kr : '',
                                상품특징 : '',
                                키워드 : '',
                                상품번호 : e.productnum ? e.productnum : '',
                                등록링크 : e.productnum ? 'https://detail.1688.com/offer/' + e.productnum + '.html' : '',
                                옵션1_이미지 : '',
                                옵션1_중국어 : '',
                                옵션2_중국어 : '',
                                옵션1_한국어 : '',
                                옵션2_한국어 : '',
                                // 옵션조합_중국어 : e.옵션조합_중국어 ? e.옵션조합_중국어 : '',
                                재고 : '',
                                옵션조합_한국어 : '',
                            }
                            this.regrowlists.push(this.deepClone(obj))                       
                        }
                    }  
                } 
            }
            // console.log(this.regrowlists)
            var ob = {};
            this.regrowlists.forEach(e => {
                if(this.reglists.length > 0){
                    if(this.reglists.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호)){
                        this.reglists.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호).상세내역.push(this.deepClone(e))
                        this.reglistsinit.find(ele => ele.상품번호 == e.상품번호 && ele.등록번호 == e.등록번호).상세내역.push(this.deepClone(e))
                    } else {
                        ob = {
                            _id : e._id,
                            checked : e.checked,
                            shownameinput : e.shownameinput,
                            changed : e.changed,
                            showrequest : e.showrequest,
                            mdcomment : e.mdcomment,
                            썸네일 : e.썸네일,
                            상세페이지 : e.상세페이지,
                            등록번호 : e.등록번호,
                            상품명 : e.상품명,
                            상품명kr : e.상품명kr,
                            상품특징 : e.상품특징,
                            키워드 : e.키워드,
                            상품번호 : e.상품번호,
                            등록링크 : e.등록링크,
                            상세내역 : [e]
                        }
                        this.reglists.push(this.deepClone(ob))
                        this.reglistsinit.push(this.deepClone(ob))
                    }
                } else {
                    ob = {
                        _id : e._id,
                        checked : e.checked,
                        shownameinput : e.shownameinput,
                        changed : e.changed,
                        showrequest : e.showrequest,
                        mdcomment : e.mdcomment,
                        썸네일 : e.썸네일,
                        상세페이지 : e.상세페이지,
                        등록번호 : e.등록번호,
                        상품명 : e.상품명,
                        상품명kr : e.상품명kr,
                        상품특징 : e.상품특징,
                        키워드 : e.키워드,
                        상품번호 : e.상품번호,
                        등록링크 : e.등록링크,
                        상세내역 : [e]
                    }
                    this.reglists.push(this.deepClone(ob))
                    this.reglistsinit.push(this.deepClone(ob))
                }
            })


            this.showlist = true;
            this.showspinner = false;
            
            
        }
    }
}
</script>
<style>
    .modal-dialog {
        max-width: 100%;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>

